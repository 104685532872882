<template>
  <div class="guide-container">
    <h1>5 reasons why people invest in cryptocurrencies</h1>

    <img
      src="@/assets/imgs/Articles/stock-market-chart-showing-volatility.jpg"
      alt="Lots of crypto coins falling from the sky onto a city"
    />
    <h2>1) Potential for high returns</h2>
    <p>
      Many cryptocurrencies have seen dramatic price increases in the past, with
      some experiencing gains of over 1,000% in a single year. This can be
      particularly appealing for investors who are looking for ways to generate
      high returns on their investments.
    </p>
    <br />
    <h2>2) Long-term appreciation</h2>
    <p>
      Some investors believe that cryptocurrencies have the potential to become
      a widely accepted form of payment in the future, which could drive up
      their value. For example, Bitcoin has been around for over a decade and
      its value has increased significantly over time.
    </p>
    <br />

    <h2>3) Diversification</h2>
    <p>
      Cryptocurrencies can be used as a way to diversify an investment portfolio
      and hedge against traditional markets. Traditional investments like stocks
      and bonds can be affected by a variety of factors, such as economic
      downturns or political instability. Cryptocurrencies, on the other hand,
      are not tied to any particular country or economy and are less vulnerable
      to these types of risks.
    </p>
    <!-- <img
      src="@/assets/imgs/Articles/WhyPeopleInvestInCrypto/a-field-of-baskets-with-dollar-notes-in-them.jpg"
      alt="A field of baskets with dollars notes in them depicting diversification"
    /> -->
    <br />
    <h2>4) Support for the technology</h2>
    <p>
      Some people invest in cryptocurrencies as a way to support the technology
      and its potential to disrupt traditional financial systems.
      Cryptocurrencies offer an alternative to traditional financial systems
      that are often opaque, slow, and expensive. Investing in cryptocurrencies
      can be seen as a way to support this alternative and potentially
      contribute to its success.
    </p>
    <br />
    <h2>5) Unique features</h2>
    <p>
      Cryptocurrencies have some unique features that set them apart, including
      being decentralized, secure, and fast and cheap to send. These features
      can make them appealing to investors who are looking for alternative
      investments with unique characteristics.
    </p>

    <br />
    <p>
      It's important to note that investing in cryptocurrencies carries risks,
      as they are highly volatile and their value can fluctuate significantly.
      It's also important to do thorough research and consider the potential
      risks before investing in any cryptocurrency.
    </p>

    <!-- <img
      src="@/assets/imgs/Articles/WhyPeopleInvestInCrypto/a-stock-market-chart-showing-high-volatility.jpg"
      alt="A stock market chart showing high volatility in the crypto currency market"
    /> -->
  </div>
</template>

<script>
export default {};
</script>
